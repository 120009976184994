import React from 'react';

const ChatMessage = (props) => {
    return (
        <div className="chat-message px-2 pb-2">
            <div className="chat-message-content-container">
                <div className="chat-message-header">
                    <div className="chat-message-sender">
                        <div className="chat-message-sender-name bold">
                            {props.senderName}
                        </div>
                        {/* <div className="chat-message-sender-picture">
                            <img src={props.sender_picture} alt="Foto do remetente"/>
                        </div> */}
                    </div>
                    {/* <div className="chat-message-date">
                        {props.date}
                    </div> */}
                </div>
                <div className="chat-message-content">
                    <div className="chat-message-content-text">
                        {props.message_text}
                    </div>
                    {/* <div className="chat-message-content-image">
                        <img src={props.message_image} alt="Imagem da mensagem"/>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default ChatMessage;