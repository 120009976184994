import React, { useEffect, useState } from "react";

import { Navigate, useNavigate, useParams } from "react-router-dom";
import Form from "../components/Form.js";
import SearchPage from "../components/SeachPage.js";
import Demand from "../components/Demand.js";
import defaultSearch from "../components/DefaultSearchConstructor.js";
import UpperMenu from "../components/UpperMenu.js";
import dbQuery from "../gets/DBQuery.js";
import DemandForm from "../components/Forms/DemandForm.js";

const Demands = (props) => {

    const navigate = useNavigate();

    const search = defaultSearch("search-page-form", (query)=>navigate("/demands/"+query));

    const {query} = useParams();

    const search_query = query && query.includes("search=") ? query.split("search=")[1].split("&")[0] : "";
    const theme = query && query.includes("theme=") ? query.split("theme=")[1].split("&")[0] : "";

    const [all_demands, setAllDemands] = useState([]);
    var demands = [];

    var owner = JSON.parse(localStorage.getItem("councilor_app_id"));

    for(let i = 0; i < all_demands.length; i++){
        let demand = all_demands[i];
        if(demand.id_receiver === owner){
            if((demand.demand_title.includes(search_query) || demand.demand_description.includes(search_query)) && demand.demand_theme.includes(theme)){
                demands.push(demand);
            }
        }
    }

    const [demands_el, setDemandsEl] = useState([]);

    var user_id = JSON.parse(localStorage.getItem("user_id"));

    useEffect(()=>{
        dbQuery("get_demands_by_user_id_with_sender_username",
        {
            demand_title: search_query,
            demand_description: search_query,
            demand_theme: theme
        },
        (new_demands)=>{
            var new_demands_el = [];
            for(let j = 0; j < new_demands.length; j++){
                var demand = new_demands[j];
                new_demands_el.push(<Demand title={demand.demand_title} description={demand.demand_description} theme={demand.demand_theme} creator={demand.sender_username}/>);
            }
            setDemandsEl(new_demands_el);
        });
    }, [query]);

    var inputs = DemandForm;

    var loggedIn = JSON.parse(localStorage.getItem("isLoggedIn"));

    if(!loggedIn){
        return <Navigate to="/login"/>;
    }

    return (
        <div>
            <UpperMenu/>
            <SearchPage form={<Form className={"search-page-form"} formFunction={search} inputs={inputs} submitButton="Pesquisar"/>} title={"Demandas"}/>
            <div className="default-container">
                <div className="search-results default-page-content-size">
                    {demands_el.length !== 0 ? demands_el :
                    <div className="event-card-container">
                        Nenhuma demanda encontrada.
                    </div>
                    }
                </div>
            </div>
        </div>
    )
};

export default Demands;