import React from "react";

const SearchPage = (props) => {
    return (
        <div>
            <div className="default-container">
                <div className="p-4">
                    {props.before_content !== undefined &&
                    <div className="py-10">
                        {props.before_content}
                    </div>
                    }
                    <div className="main-content">
                        <div>
                            <div className="title">{props.title}</div>
                            <div className="pb-1">
                                {props.form}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {props.results !== undefined && props.results.length > 0 &&
            <div className="default-container">
                <div className="default-page-content-size">
                    <div className="search-results px-4">
                        {props.results}
                    </div>
                </div>
            </div>}
        </div>
    )
};

export default SearchPage;