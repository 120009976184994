import React from "react";
import DefaultProfilePicture from "../images/default-profile-picture.png"
import Post from "../components/Post.js"
import EditIcon from "../images/pencil.png"
import ImgIcon from "../images/image.png"
import { Link } from "react-router-dom";
import ProfileSocial from "../components/ProfileSocial.js";
import { useState, useEffect } from "react";
import dbQuery from "../gets/DBQuery.js";
import fetchContent from "../gets/Fetch.js";
import { api_base_url, db_base_url } from "../App.js";


const getPosts = (postsData, media = {}) => {
    //add media to posts
    for(var i = 0; i < Object.keys(media).length; i++){
        var key = Object.keys(media)[i];
        var media_item = media[key];
        var media_post_id = media_item.news_id;
        for(var j = 0; j < Object.keys(postsData).length; j++){
            var post_key = Object.keys(postsData)[j];
            var post = postsData[post_key];
            if(post.id === media_post_id){
                if(!post.media){
                    post.media = [];
                }
                post.media.push(media_item);
            }
        }
    }

    var posts = [];
    for(var i = 0; i < Object.keys(postsData).length; i++){
        var key = Object.keys(postsData)[i];
        var post = postsData[key];
        posts.push(
            <Post title={post.news_title} description={post.news_description} media={post.media}/>
        );
    }
    return posts;
}

const getSocials = (socialsData, isEditable) => {
    var socials = [];
    
    var keys = Object.keys(socialsData);

    for(var i = 0; i < keys.length; i++){
        var social = socialsData[keys[i]];
        socials.push(
            <ProfileSocial social={social} isEditable={isEditable}/>
        );
    }
    return socials;
}

const Profile = (props) => {

    const [profileData, setProfileData] = useState({});
    const [posts, setPosts] = useState([]);
    const [socials, setSocials] = useState([]);
    const [isCouncilor, setIsCouncilor] = useState(false);
    const [profilePictureURL, setProfilePictureURL] = useState(undefined);

    const user_id = JSON.parse(localStorage.getItem("user_id"));
    const profileOwner = props.profileOwner;
    const profileIsAppOwner = JSON.parse(localStorage.getItem("councilor_app_id")) == profileOwner;
    const isOwner = user_id == profileOwner;
    var isEditable = props.isEditable? props.isEditable : false;

    if(!isOwner){
        isEditable = false;
    }

    const getProfilePictureDB = (user_id, found_callback, not_found_callback, default_callback = ()=>{}) =>{
        dbQuery("get_profile_picture_by_user_id", {
            id: user_id
        }, (data)=>{
            if(data.length > 0){
                var new_profile_pic = db_base_url + data[0].profile_pic;
                setProfilePictureURL(new_profile_pic);
                found_callback();
            }
            else{
                not_found_callback();
            }
            default_callback();
        });
    }

    const getProfilePictureAPI = (councilor_id, found_callback, not_found_callback, default_callback = ()=>{}) =>{
        let uri = api_base_url+'/api/parlamentares/parlamentar/search_parlamentares/';

        fetchContent(uri, null, 'GET', (data)=>{
            var profiles = data;
            var profiles = profiles.filter((profile)=>{
                return profile.id == councilor_id;
            });
            if(profiles.length > 0){
                var profile = profiles[0];
                if(profile.fotografia_cropped !== ""){
                    setProfilePictureURL(api_base_url+profile.fotografia_cropped);
                    found_callback();
                }
                else{
                    not_found_callback();
                }
            }
            else{
                not_found_callback();
            }
            default_callback();
        });
    }

    useEffect(()=>{
        setProfileData({});
        dbQuery("get_user_by_id", {
            id: props.profileOwner
        }, (data)=>{
            if(data.length > 0){
                data = data[0];
                setProfileData(data);
            }
        });
        setProfilePictureURL(undefined);
        getProfilePictureDB(props.profileOwner, ()=>{}, ()=>{
            dbQuery("get_councilor_by_user_id", {
                id: props.profileOwner
            }, (data)=>{
                if(data.length > 0){
                    var councilor_id = data[0].id_ex_parlamentary;
                    getProfilePictureAPI(councilor_id, ()=>{}, ()=>{});
                }
            });
        });
        setIsCouncilor(false);
        dbQuery("get_councilor_by_user_id", {
            id: props.profileOwner
        }, (data)=>{
            if(data.length > 0){
                setIsCouncilor(true);
            }
        });
        setPosts([]);
        dbQuery("get_news_by_user_id", {
            id: props.profileOwner
        }, (data)=>{
            var media = data.files;
            var news = data.news;
            var new_posts = getPosts(news, media);
            setPosts(new_posts);
        });
        setSocials([]);
        dbQuery("get_socials_by_user_id", {
            id: props.profileOwner
        }, (data)=>{
            var new_socials = getSocials(data, isEditable);
            setSocials(new_socials);
        });
    }, [profileOwner]);

    const postNews = (event) => {
        event.preventDefault();

        var { title, text, media } = document.getElementsByClassName("post-form")[0];

        if(!title.value || !text.value){
            alert("Uma publicação precisa de um título e um descritivo.");
            return;
        }

        //encode image to base64

        var image = media.files[0];

        var base64 = "";

        if(image){
            var reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = function () {
                base64 = reader.result;

                dbQuery("insert_news", {
                    news_title: title.value,
                    news_description: text.value,
                    news_link: "",
                    news_date: new Date().toISOString()
                }, (data)=>{
                    //make dbQuery to get last news id
                    dbQuery("get_last_post_id_by_user_id", {
                        user_id: user_id,
                    }, (data)=>{
                        var post_id = data[0].id;
                        //make dbQuery to insert media
                        dbQuery("post_media_upload", {
                            news_id: post_id,
                            media: {name: image.name, type: image.type, base64: base64}
                        }, (data)=>{
                            alert("Notícia publicada com sucesso!");
                            window.location.reload();
                        });
                    });
                });
            };
        }
        else{
            dbQuery("insert_news", {
                news_title: title.value,
                news_description: text.value,
                news_link: "",
                news_date: new Date().toISOString()
            }, (data)=>{
                alert("Notícia publicada com sucesso!");
                window.location.reload();
            });
        }
    };

    const handleModifyProfile = (event) => {
        event.preventDefault();

        var form = document.getElementsByClassName("modify-profile-form")[0];
        
        var old_user_data = profileData;

        var user = {
            username: old_user_data.username,
            bio: old_user_data.bio
        };

        var old_socials = [];

        for(var i = 0; i < socials.length; i++){
            old_socials.push(socials[i].props.social.id);
        }

        var new_socials = {};

        var deleted_socials = [];

        for(var i = 0; i < form.length; i++){
            var input = form[i];
            if(input.value && input.value !== ""){
                if(input.name === "username"){
                    user.username = input.value;
                }
                else if(input.name === "bio"){
                    user.bio = input.value;
                }
                else if(input.type === "text"){

                    //check if social existed in old_socials
                    for(var j = 0; j < old_socials.length; j++){
                        if(old_socials[j] === input.name){
                            deleted_socials.push(old_socials[j]);
                        }
                    }

                    var socialLink = input.value;

                    var socialName = socialLink.split(".")[0];

                    if(socialName.includes("//")){
                        socialName = socialName.split("//")[1];
                    }

                    if(socialName.includes("www")){
                        socialName = socialName.split(".")[1];
                    }

                    new_socials[socialName] = socialLink;
                }
            }
        }

        var delete_socials = (callback) => {
            var delete_reload_counter = 0;
            for (var i = 0; i < deleted_socials.length; i++) {
                dbQuery("delete_social_by_social_id_and_user_id", {
                    deletion_time: new Date().toISOString(),
                    social_id: deleted_socials[i],
                }, (data)=>{
                    delete_reload_counter++;
                    if(delete_reload_counter === deleted_socials.length){
                        callback();
                    }
                });
            }
        }

        var insert_socials = (callback) => {
            var reload_counter = 0;
            for (const [key, value] of Object.entries(new_socials)) {
                dbQuery("insert_social", {
                    social_name: key,
                    social_link: value,
                }, (data)=>{
                    reload_counter++;
                    if(reload_counter === Object.keys(new_socials).length){
                        callback();
                    }
                });
            }
        }

        var modification_success_alert = () => {
            alert("Perfil modificado com sucesso!");
            window.location.reload();
        }

        dbQuery("modify_profile_by_id", {
            username: user.username,
            bio: user.bio,
        }, (data)=>{
            if(deleted_socials.length === 0){
                if(Object.keys(new_socials).length === 0){
                    modification_success_alert();
                }
                else{
                    insert_socials(()=>{
                        modification_success_alert();
                    });
                }
            }
            else{
                delete_socials(()=>{
                    if(Object.keys(new_socials).length === 0){
                        modification_success_alert();
                    }
                    else{
                        insert_socials(()=>{
                            modification_success_alert();
                        });
                    }
                });
            }
        });
    }

    const addImg = (id) => {
        document.getElementById(id).click();
    }

    const handleProfilePictureUpload = (event) => {
        event.preventDefault();

        var { media } = document.getElementsByClassName("profile-picture-form")[0];

        var image = media.files[0];

        var base64 = "";

        var image_name = image.name;

        if(image){
            var reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = function () {
                base64 = reader.result;

                dbQuery("profile_pic_upload", {
                    profile_pic: {name: image.name, type: image.type, base64: base64}
                }, (data)=>{
                    alert("Foto de perfil atualizada com sucesso!");
                    window.location.reload();
                });
            };
        }
    }

    return (
        <div className="default-page-content-size">
            <div className="profile-header">
                <div className="navigation">
                    <div className="profile centered-flex-container pb-3">
                    <div className="profile-identity d-flex-column">
                            <div className="relative">
                                <div className="profile-picture-container">
                                    <img className="profile-picture" src={profilePictureURL ? profilePictureURL : DefaultProfilePicture} alt="Profile picture"/>
                                </div>
                                {isEditable &&
                                <div className="absolute w-100 h-100">
                                    <form className="profile-picture-form w-100 h-100" onSubmit={handleProfilePictureUpload}>
                                        <div className="v-hidden">
                                            <input type="file" accept="image/*" name="media" id="media" onChange={handleProfilePictureUpload}/>
                                        </div>
                                        <div className="w-100 h-100 link rounded-border" onClick={()=>{
                                            var media = document.getElementById("media");
                                            media.click();
                                        }}></div>
                                    </form>
                                </div>}
                            </div>
                        </div>
                        <div className="padding w-100"></div>
                        {!isEditable && isOwner &&
                            <div className="profile-options d-flex">
                                <div className="icon-button d-flex">
                                    <Link to="/modify_profile">
                                        <img className="icon" src={EditIcon} alt="Editar"/>
                                    </Link>
                                </div>
                            </div>
                        }
                    </div>
                    <form className="modify-profile-form" onSubmit={handleModifyProfile}>
                        <div className="profile-name bold pb-1">
                            {!isEditable?
                            (profileData && profileData.username)
                            :
                            <input name="username" type="text" placeholder={profileData.username} className="w-100"/>
                            }
                        </div>
                        <div className={"profile-description "+(profileData && profileData.bio? "pb-1" : "")}>
                            {!isEditable?
                            (profileData && profileData.bio)
                            :
                            <textarea name="bio" placeholder={profileData.bio} className="w-100"></textarea>
                            }
                        </div>
                        <div>
                            {isCouncilor &&
                            <div className={"socials " + (socials.length === 0 ? "" : " pt-1 pb-2 ") + (!isEditable && "d-flex")}>
                                {socials}
                            </div>}
                            {!isEditable &&
                                <div>
                                    {isCouncilor &&
                                    <div className="schedule pb-1">
                                        <Link className="link" to="/schedule_search">
                                            Agenda
                                        </Link>
                                    </div>}
                                    {isCouncilor &&
                                    (<div className="contact">
                                        {isOwner ?
                                        <Link className="link" to="/demands">
                                            Demandas
                                        </Link>
                                        :
                                        <Link className="link" to="/demand">
                                            Fale conosco
                                        </Link>
                                        }
                                    </div>)}
                                </div>
                            }
                        </div>
                        {isEditable &&
                        <div className="d-flex pt-2">
                            <div className="w-100"></div>
                            <input type="submit" class="small-form-btn" value="Confirmar mudanças"/>
                        </div>
                        }
                    </form>
                </div>
            </div>
            {isCouncilor && 
            <div className="feed">
                {isOwner &&
                <div className="new-post d-flex">
                    <form className="post-form bg-white p-3" onSubmit={postNews}>
                        <div className="post-content title">
                            <input name="title" className="w-100 border-bottom-0 pb-1" type="text" placeholder="Título da notícia"/>
                            <textarea name="text" className="w-100" type="text" placeholder="Texto da notícia"/>
                        </div>
                        <div className="post-options d-flex">
                            <div className="post-media p-4 d-flex align-items-center">
                                <img className="icon mouse-pointer-hover" src={ImgIcon} alt="Imagem" onClick={()=>addImg("post-media")}/>
                            </div>
                            <div className="w-100"></div>
                            <div className="post-action-button d-flex align-items-center py-4 px-3">
                                <input type="submit" className="small-form-btn" value="Publicar"/>
                            </div>
                        </div>
                        <div className="v-hidden">
                            <input type="file" accept="image/*" name="media" id="post-media"/>
                        </div>
                    </form>
                </div>}
                {posts.length !== 0 &&
                <div className="posts">
                    {posts}
                    <div className="pb-4"></div>
                </div>}
            </div>
            }
        </div>
    )
};

export default Profile;