import React from "react";
import { useNavigate, Link } from 'react-router-dom';
import PageForm from "../components/PageForm";
import dbQuery from "../gets/DBQuery";

const Content = (props) => {
  return (
    <div>
      <form className="main-form" onSubmit={props.formFunction}>
        {/* <div className="justify-text gray-text pb-1">
            Contribua e faça diferença na vida de pessoas que precisam através da doação de alimentos. Acesse sua conta para começar a ajudar!
        </div> */}
        {/* <div className="pt-5 px-10">
            <img className="w-100" src={logo}></img>
        </div> */}
        <div className="input-container">
            <input placeholder="Email" type="email" name="email" required />
        </div>
        <div className="input-container">
            <input placeholder="Senha" type="password" name="pass" required />
        </div>
        <div className="w-100 button-container">
            <input type="submit" className="form-btn" value="Entrar"/>
        </div>
      </form>
      <Link to="/registration">
          <div className="w-100 button-container">
              <input type="submit" className="form-btn" value="Criar Conta"/>
          </div>
      </Link>
    </div>
  )
}

const Login = (props) => {

    localStorage.setItem("councilor_app_id", JSON.stringify(1));

    const navigate = useNavigate();

    const handleLogin = (event) => {
        event.preventDefault();
    
        var { email, pass } = document.getElementsByClassName("main-form")[0];

        //check for existing user in db
        dbQuery("get_id_by_email", {
            email: email.value,
            hashed_password: pass.value
        }, (data)=>{
            if(data.length !== undefined && data.length === 0){
                alert("Email ou senha incorretos.");
                return;
            }
            else{
                let auth = {
                    token: data.token,
                    user_id: data.user_id
                }
                localStorage.setItem("auth", JSON.stringify(auth));
                localStorage.setItem("user_id", JSON.stringify(data.user_id));
                localStorage.setItem("isLoggedIn", JSON.stringify(true));
                navigate("/home");
            }
        });
    };

    return (
        <div className="default-container">
            <div className="p-10">
                <PageForm title={"Login"} content={<Content formFunction={handleLogin}/>}/>
            </div>
        </div>
    )
};

export default Login;