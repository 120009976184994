import dbQuery from "../gets/DBQuery.js";

const getEvents = (search={}, callback = (events)=> {}) => {

    var user_id = localStorage.getItem("councilor_app_id");
    
    dbQuery("get_schedule_by_user_id", {user_id: user_id}, (data)=>{
        var all_events = data;

        var events = [];

        for(let i = 0; i < all_events.length; i++){
            let event = all_events[i];

            let start = event.schedule_start;
            let end = event.schedule_end;

            if(event.user_id == user_id){
                var keys = Object.keys(search);

                var validDate = false;

                if(search.start || search.end){
                    if(!end){
                        end = start;
                    }
                    if(!search.end){
                        search.end = search.start;
                    }
                    if(start && search.end){
                        if(start > search.end){
                            continue;
                        }
                        validDate = true;
                    }
                    if(end && search.start){
                        if(end < search.start){
                            continue;
                        }
                        validDate = true;
                    }
                }
                else{
                    validDate = true;
                }

                if(!validDate){
                    continue;
                }

                var validData = false;

                for(let j = 0; j < keys.length; j++){
                    let key = keys[j];
                    if(key == "start" || key == "end"){
                        continue;
                    }
                    if(event[key] === undefined){
                        event[key] = "";
                    }
                    if(search[key] === undefined){
                        search[key] = "";
                    }
                    if(event[key].toString().toLowerCase().includes(search[key].toString().toLowerCase())){
                        validData = true;
                        break;
                    }
                }

                if(!validDate || !validData){
                    continue;
                }

                var event_el = {
                    title: event.schedule_title,
                    start: start,
                    end: end,
                };

                events.push(event_el);
            }
        }

        callback(events);
    });
}

export default getEvents;