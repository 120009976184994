import React from "react";
import { db_base_url } from "../App";

const Post = (props) => {

    var media = props.media;
    var rendered_media = [];

    for (var key in media){
        var el = media[key].news_pic;
        rendered_media.push(
            <div className="post-img-container">
                <img className="w-100" src={db_base_url+el}/>
            </div>
        );
    }
    
    return (
        <div className="feed-post d-flex">
            <div className="post-form bg-white py-3 px-4">
                <div className="title pb-1">
                    {props.title}
                </div>
                <div className="post-content pb-2">
                    {props.description}
                </div>
                {props.media &&
                rendered_media}
            </div>
        </div>
    )
};

export default Post;