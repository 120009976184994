const defaultSearch = (formClassName, goto) => {
    const search = (e) =>{
        e.preventDefault();
        var form = document.getElementsByClassName(formClassName)[0];
        var query = '';
        for(var i = 0; i < form.length; i++){
            var input = form[i];
            if(input.value !== '' && input.type !== 'submit'){
                if(query !== ''){
                    query += '&';
                }
                query += input.name+'='+encodeURIComponent(input.value);
            }
        }
        goto(query);
    }
    return search;
}

export default defaultSearch;