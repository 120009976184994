import React from "react";

import "./styles.css";

import Router from "./routes";

import GetNotifications from "./gets/GetNotifications";

function App() {

  setInterval(GetNotifications(), 5*60*1000);

  return (
    <Router/>
  );
}

export default App;

export const api_base_url = "https://sapl.riogrande.rs.leg.br";

export const db_base_url = "https://camararggabinetedigital-backend.twinfo.com.br/public_html/";