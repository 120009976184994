const fetchContent = async (uri, body, method, callback = ()=>{}, max = 50) => {
    //make recursive call for api pagination if necessary

    const recursiveFetch = async (uri, body, method, last_data = {}) => {
        await fetch(uri, {
            method: method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: body
        })
        .then((resp) => {
            return resp.json();
        })
        .then((data) => {
            if(data.pagination){
                //concatenate the results
                if(last_data.results === undefined){
                    last_data.results = [];
                }
                data.results = last_data.results.concat(data.results);
            }
            if(data.results && data.pagination.next_page && data.results.length < max){
                recursiveFetch(data.pagination.links.next, body, method, data);
            }
            else{
                callback(data);
            }
        });
    }

    recursiveFetch(uri, body, method, callback);
}

export default fetchContent;