import dbQuery from "../gets/DBQuery";

const handleNewEvent = (event) => {
    event.preventDefault();

    var { name, description, start_time, end_time } = document.getElementsByClassName("new-event-form")[0];

    var newEvent = {
        schedule_title: name.value,
        schedule_description: description.value,
        schedule_start: start_time.value,
        schedule_end: end_time.value,
    };

    dbQuery("insert_schedule_event", newEvent, (data)=>{
        alert("Evento criado com sucesso!");
        window.location.reload();
    });
};

export default handleNewEvent;