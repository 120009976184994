const newDemandForm = {
    1:{
        placeholder:"Título", type:"textarea", name:"title", required:true
    },
    2:{
        placeholder:"Tema", type:"textarea", name:"theme"
    },
    3:{
        placeholder:"Descrição", type:"textarea", name:"description", required:true
    },
    4:{
        btn:true, type:"submit", value:"Enviar demanda", required:true
    },
}

export default newDemandForm;