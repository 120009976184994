const DemandForm = {
    1:{
        placeholder:"Termos da pesquisa", type:"text", name:"search"
    },
    2:{
        placeholder:"Tema", type:"text", name:"theme"
    },
    3:{
        btn:true, type:"submit", value:"Pesquisar", required:true
    }
}

export default DemandForm;