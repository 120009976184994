import React from "react";

import FacebookIcon from "../images/facebook.png"
import TwitterIcon from "../images/twitter.png"
import YoutubeIcon from "../images/youtube.png"
import { Link } from "react-router-dom";

const ProfileSocial = (props) => {

    var isEditable = props.isEditable? props.isEditable : false;
    var socialLink = props.social.social_link;
    var socialIcon = props.socialIcon? props.socialIcon : null;

    var socialName = props.social.id;

    if(socialLink.includes("facebook")){
        socialIcon = FacebookIcon;
    }
    else if(socialLink.includes("twitter")){
        socialIcon = TwitterIcon;
    }
    else if(socialLink.includes("youtube")){
        socialIcon = YoutubeIcon;
    }

    if(!socialLink.includes("https://")){
        socialLink = "https://" + socialLink;
    }
    else if(!socialLink.includes("https://www.")){
        socialLink = "https://www." + socialLink;
    }

    return (
        <div className="social px-1">
            {isEditable?
                <input name={socialName} type="text" placeholder={socialLink} className="w-100"/>
                :
                <a href={socialLink} target="_blank">
                    <img className="icon" src={socialIcon}/>
                </a>
            }
        </div>
    )
};

export default ProfileSocial;