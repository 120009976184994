import dbQuery from "../gets/DBQuery.js";
import { api_base_url } from "../App";
import PushNotification from "../components/PushNotification.js";
import fetchContent from "../gets/Fetch.js";
import Brasao from "../images/brasao_rg.png";

const GetNotifications = () => {

    var user_id = undefined;
    var get_notifications = true;

    try{
        user_id = JSON.parse(localStorage.getItem("user_id"));
    }catch(e){
        get_notifications = false;
    }

    if(window.location.href === "/notifications"){
        get_notifications = false;
    }

    var unread_propositions = [];
    var unread_demands = [];
    var last_access = undefined;

    const setUnreadPropositions = (data) => {
        unread_propositions = data;
    }
    const setUnreadDemands = (data) => {
        unread_demands = data;
    }
    const setLastAccess = (data) => {
        last_access = data;
    }

    var unread_propositions_loaded = false;
    var unread_demands_loaded = false;
    var last_access_loaded = false;

    const setUnreadPropositionsLoaded = (data) => {
        unread_propositions_loaded = data;
    }
    const setUnreadDemandsLoaded = (data) => {
        unread_demands_loaded = data;
    }
    const setLastAccessLoaded = (data) => {
        last_access_loaded = data;
    }

    const filterByFirstOccurrence = (array, key) => {
        var result = [];
        var map = new Map();
        for (const item of array) {
            if(!map.has(item[key])){
                map.set(item[key], true);
                result.push(item);
            }
        }
        return result;
    }

    const filterByKeyValues = (array, key, values) => {
        var result = [];
        for (var i = 0; i < array.length; i++) {
            if(values.includes(array[i][key]) || values.includes(array[i][key].toString())){
                result.push(array[i]);
            }
        }
        return result;
    }

    const listOfKey = (array, key) => {
        var result = [];
        for (var i = 0; i < array.length; i++) {
            result.push(array[i][key]);
        }
        return result;
    }

    const filterByConditionIfKeyMatches = (array1, array2, key1, key2, condition) => {
        //sort array1 by key1
        array1.sort((a, b)=>{return a[key1] - b[key1]});
        //sort array2 by key2
        array2.sort((a, b)=>{return a[key2] - b[key2]});

        //filter array1 by condition
        var result = [];
        var i = 0;
        var j = 0;
        while(i < array1.length && j < array2.length){
            if(array1[i][key1] == array2[j][key2]){
                if(condition(array1[i], array2[j])){
                    result.push(array1[i]);
                }
                i++;
                j++;
            }
            else if(array1[i][key1] < array2[j][key2]){
                i++;
            }
            else{
                j++;
            }
        }

        return result;
    }

    const callback = () => {
        if(get_notifications){
            if(unread_propositions_loaded && unread_demands_loaded && last_access_loaded){
                var new_notifications = unread_propositions.concat(unread_demands);

                var new_notification_found = false;
                if(new_notifications.length > 0){
                    if(new Date(new_notifications[0].last_modified) > last_access){
                        new_notification_found = true;
                    }
                }
            }
            if(new_notification_found){
                PushNotification("Nova notificação", {body: "Você tem novas notificações.", icon: Brasao});
            }
        }
    }

    if(get_notifications){
        dbQuery("get_last_access_by_user_id", {}, (data)=>{
            if(data.length > 0){
                let last_access = data[0].last_access;
                last_access = new Date(last_access);
                setLastAccess(last_access);
                setLastAccessLoaded(true);
                dbQuery("set_last_access_by_user_id", {
                    last_access: new Date().toISOString(),
                }, (data)=>{
                    callback();
                });
            }
        });
        dbQuery("get_propositions_followed_by_user_id", {}, (data)=>{
            let propositions_info = data;

            //filter propositions_info to have only the first occurrence of each proposition_id

            propositions_info = filterByFirstOccurrence(propositions_info, "proposition_id");

            //filter propositions_info to have only the propositions followed by the user

            propositions_info = filterByKeyValues(propositions_info, "follow", ["1"]);

            //make a list of propositions ids followed by the user

            let propositions_ids = listOfKey(propositions_info, "proposition_id");

            //for each proposition_id
            let followed_propositions = [];
            let counter = 0;
            for(let i = 0; i < propositions_ids.length; i++){
                let url = api_base_url+"/api/materia/proposicao/?numero_proposicao="+propositions_ids[i];
                fetchContent(url, null, "GET", (data)=>{
                    data = data.results;
                    followed_propositions = followed_propositions.concat(data);
                    counter++;

                    if(counter === propositions_ids.length){
                        //filter the propositions that were modified after the user followed them

                        let unread_propositions = filterByConditionIfKeyMatches(followed_propositions, propositions_info, "numero_proposicao", "proposition_id", (a, b)=>{
                            let time_followed = new Date(b.time_followed);
                            let ultima_edicao = new Date(a.ultima_edicao);
                            return time_followed < ultima_edicao;
                        });

                        for(let i = 0; i < unread_propositions.length; i++){
                            unread_propositions[i].name = "Atualização de proposição";
                            unread_propositions[i].description = unread_propositions[i].descricao;
                            unread_propositions[i].last_modified = unread_propositions[i].ultima_edicao;
                            //unread_propositions[i].link = "/proposition/"+unread_propositions[i].numero_proposicao;
                        }

                        setUnreadPropositions(unread_propositions);

                        setUnreadPropositionsLoaded(true);
                        callback();
                    }
                });
            }
            if(propositions_ids.length === 0){
                setUnreadPropositionsLoaded(true);
                callback();
            }
        });
        dbQuery("get_demands_by_user_id_with_sender_username", {
            demand_title: "",
            demand_description: "",
            demand_theme: ""
        }, (data)=>{
            var demands_info = data;

            demands_info = demands_info.reverse();

            for(let i = 0; i < demands_info.length; i++){
                demands_info[i].name = "Nova demanda";
                demands_info[i].description = demands_info[i].demand_description;
                demands_info[i].last_modified = demands_info[i].demand_date;
                //demands_info[i].link = "/demand/"+demands_info[i].id;
            }

            setUnreadDemands(demands_info);

            setUnreadDemandsLoaded(true);
            callback();
        });
    }
}

export default GetNotifications;