import React from "react";

const Demand = (props) => {
    return (
        <div className="pb-4">
            <div className="event-card-container w-100">
                <div className="event-card-title">{props.title}</div>
                <div className="event-card-content pb-1">{props.creator}</div>
                <div className="pb-1">
                    <div className="event-card-content pb-1 default-border-bottom gray-text">{props.theme}</div>
                </div>
                <div className="event-card-content">{props.description}</div>
            </div>
        </div>
    )
}

export default Demand;