import React, { useState } from "react";
import burgerIcon from "../images/burgerIcon.png"
import homeIcon from "../images/homeIcon.png"
import { useNavigate, Link, Navigate } from 'react-router-dom';

export const logoff = () => {
    localStorage.setItem("auth", JSON.stringify({token: undefined, user_id: undefined}));
    localStorage.setItem("user_id", JSON.stringify(undefined));
    localStorage.setItem("isLoggedIn", JSON.stringify(false));
    localStorage.setItem("councilor_app_id", JSON.stringify(1));
}

const UpperMenu = (props) => {

    const navigate = useNavigate();

    const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);

    var loggedIn = JSON.parse(localStorage.getItem("isLoggedIn"));

    if(!loggedIn){
        return <Navigate to="/login"/>;
    }

    const toggleBurgerMenu = () => {
        setIsBurgerMenuOpen(!isBurgerMenuOpen);
    };

    const handleLogoff = (e) => {
        e.preventDefault();
        logoff();
        navigate("/login");
    }

    var isOwner = JSON.parse(localStorage.getItem("user_id")) == JSON.parse(localStorage.getItem("councilor_app_id"));

    return (
        <div className="w-100 d-flex center-content box-shadow-small bg-white">
            <div className="screen-area">
                <div className="upper-menu-bar">
                    <Link to="/home" className="d-flex">
                        <img className="home-icon p-1" src={homeIcon} alt="Menu" />
                    </Link>
                    <div className="w-100">
                    </div>
                    <div className="d-flex burger-menu-button" onClick={toggleBurgerMenu}>
                        <img className="burger-icon p-1" src={burgerIcon} alt="Menu" />
                        <div className="relative">
                        {isBurgerMenuOpen && (
                            <div className="burger-menu-dropdown">
                                <Link to="/proposition_search">
                                    <button className="burger-dropdown-menu-button">Proposições</button>
                                </Link>

                                <Link to="/notifications">
                                    <button className="burger-dropdown-menu-button">Notificações</button>
                                </Link>

                                <Link to="/chats">
                                    <button className="burger-dropdown-menu-button">Mensagens</button>
                                </Link>

                                {!isOwner && <Link to="/profile">
                                    <button className="burger-dropdown-menu-button">Perfil</button>
                                </Link>}

                                <form onClick={handleLogoff}>
                                    <button className="burger-dropdown-menu-button">{loggedIn ? "Sair" : "Login"}</button>
                                </form>
                            </div>
                        )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UpperMenu;