const EventForm = {
    1:{
        placeholder:"Evento", type:"textarea", name:"name", required:true
    },
    2:{
        placeholder:"Horário de início", type:"date", name:"start_time", required:true
    },
    3:{
        placeholder:"Horário de fim", type:"date", name:"end_time"
    },
    4:{
        placeholder:"Descrição", type:"textarea", name:"description"
    },
    5:{
        btn:true, type:"submit", value:"Criar evento", required:true
    },
}

export default EventForm;