import React from 'react';
import { Link } from 'react-router-dom';

const ChatSelectButton = (props) => {
    return (
        <Link to={props.chat.id && ("/chat/" + props.chat.id)}>
            <div className="chat-select-button px-2 pb-2">
                <div className="chat-select-button-container">
                    <div className="chat-select-button-header">
                        <div className="chat-sender">
                            <div className="chat-sender-name bold">
                                {props.chat.sender_username}
                            </div>
                        </div>
                    </div>
                    <div className="chat-select-button-content">
                        <div className="chat-select-button-content-text">
                            {props.chat.demand_title}
                        </div>
                        {props.chat.demand_theme && <div className="chat-select-button-content-text">
                            Tema: {props.chat.demand_theme}
                        </div>}
                        {props.chat.demand_description && <div className="chat-select-button-content-text">
                            Descrição: {props.chat.demand_description}
                        </div>}
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default ChatSelectButton;